<!--
 * @Author: 前胡
 * @LastEditors: 胡椒
 * @Date: 2020-03-05 15:14:50
 * @LastEditTime: 2020-08-24 13:54:51
 * @Description: 账号管理搜索条
 -->
<template>
  <a-form class="filter-condition-form" layout="inline" :form="form" @submit="handleFormSubmit">
    <a-form-item>
      <a-input v-model="searchParams.search"  style="width: 240px" placeholder="手机号/花名" allowClear />
    </a-form-item>
    <a-form-item>
      <a-select style="width: 120px" placeholder="状态" v-model="searchParams.is_active" allowClear>
        <a-select-option v-for="item in isActiveOptions" :key="item.value">{{item.label}}</a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item>
      <a-button type="primary" html-type="submit" :loading="loading">查询</a-button>
    </a-form-item>
  </a-form>
</template>
<script>
// 状态options
const isActiveOptions = [
  { label: "正常", value: true },
  { label: "停用", value: false },
];


export default {
  name: "AccountSearchBar",
  props: {
    searchParams: {
      type: Object,
      default: function() {
        return {};
      }
    },
    loading: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      isActiveOptions,
      form: this.$form.createForm(this)
    };
  },
  methods: {
    handleFormSubmit(e) {
      e.preventDefault();
      this.form.validateFields(err => {
        if (!err) {
          this.$emit("callback", this.searchParams);
        }
      });
    },
  }
};
</script>
<style lang='less' scoped>
</style>
