<!--
 * @Description: 修改物流信息
 * @Author: 前胡
 * @LastEditors: 胡椒
 * @Date: 2020-03-13 10:56:09
 * @LastEditTime: 2020-08-26 15:18:42
 -->
 <template>
  <div class="account-modal">
      <a-form>
        <a-form-item label="花名" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
          {{data.username}}
        </a-form-item>
        <a-form-item label="手机号" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
          {{data.mobile}}
        </a-form-item>
        <a-form-item label="工号" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
          {{data.employee_no}}
        </a-form-item>
        <a-form-item label="部门" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
          {{data.department}}
        </a-form-item>
        <a-form-item label="岗位" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
          {{data.station}}
        </a-form-item>
        <a-form-item label="邮箱" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
          {{data.email}}
        </a-form-item>
        <a-form-item label="角色" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
          {{data.roles.map(i=>i.role_name).join(',')}}
        </a-form-item>
        <a-form-item label="状态" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
          <a-tag v-if="data.is_active===false" color="red">停用</a-tag>
          <a-tag v-if="data.is_active===true" color="green">正常</a-tag>
        </a-form-item>
      </a-form>
  </div>
</template>
<script>
export default {
  name: "AccountDetailDrawer",
  props: {
    data: {
      default: function() {
        return {};
      }
    },
    rolesOptions: {
      default: function() {
        return [];
      }
    }
  },
  data() {
    return {
      accountModalVisible: true,
      isPosting: false,
      postData: {
        is_active: true,
        role_ids: []
      },

    };
  },
};
</script>
<style lang="less" scoped>
.mr-20 {
  margin-right: 20px;
}
</style>
