<!--
 * @Description: 用户信息
 * @Author: 前胡
 * @LastEditors: 胡椒
 * @Date: 2020-03-13 10:56:09
 * @LastEditTime: 2020-08-26 15:13:54
 -->
 <template>
  <div class="account-modal">
      <a-form :form="accountForm">
        <a-form-item label="花名" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
          <a-input
            style="width:350px;"
            v-decorator="['postData.username', { rules: [{ required: true, message: '请输入花名' },{
            max:40,
            message: '最多40个字',
          },{ validator: checkDigest40 }] }]"
          />
        </a-form-item>
        <a-form-item label="手机号" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
          <a-input
            style="width:350px;"
            v-decorator="['postData.mobile', { rules: [{ required: true, message: '请输入手机号' },{ validator: checkPhoneNumber }] }]"
          />
        </a-form-item>
        <a-form-item label="工号" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
          <a-input
            style="width:350px;"
            v-decorator="['postData.employee_no', { rules: [{ required: true, message: '请输入工号' },{
              validator: checkNotChineseandnoelse,
          }] }]"
          />
        </a-form-item>
        <a-form-item label="部门" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
          <a-input
            style="width:350px;"
            v-decorator="['postData.department', { rules: [{ required: true, message: '请输入部门' },{
            max:40,
            message: '最多40个字',
          }] }]"
          />
        </a-form-item>
        <a-form-item label="岗位" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
          <a-input
            style="width:350px;"
            v-decorator="['postData.station', { rules: [{ required: true, message: '请输入岗位' },{
            max:40,
            message: '最多40个字',
          }] }]"
          />
        </a-form-item>
        <a-form-item label="邮箱" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
          <a-input
            style="width:350px;"
            v-decorator="['postData.email', { rules: [{ required: true, message: '请输入邮箱' },{
                type: 'email',
                message: '邮箱格式不准确!',
              },{
            max:40,
            message: '最多40个字',
          }] }]"
          />
        </a-form-item>
        <a-form-item label="角色" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
          <a-checkbox-group :options="rolesOptions" v-decorator="['postData.role_ids', { rules: [{ required: true, message: '请选择角色' }] }]" />
        </a-form-item>
        <a-form-item label="状态" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
          <a-radio-group v-decorator="['postData.is_active', { rules: [{ required: true, message: '请选择状态' }] }]">
            <a-radio :value="true">正常</a-radio>
            <a-radio :value="false">停用</a-radio>
          </a-radio-group>
          <a-alert message="创建成功默认密码为Gm099527" type="info" style="width:350px;" />
        </a-form-item>
      </a-form>
      <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
        <a-button class="mr-20" @click="handleHideThisModal">取消</a-button>
        <a-button
          type="primary"
          html-type="submit"
          @click="handleCreateEditAccountData"
          :loading="isPosting"
        >保存</a-button>
      </a-form-item>
  </div>
</template>
<script>
import { postAdminUser, patchAdminUser } from "@/service/setting";
import { checkPhoneNumber, checkDigest40,checkNotChineseandnoelse } from "@/utils/validator";

export default {
  name: "AccountDrawer",
  props: {
    data: {
      default: function() {
        return {};
      }
    },
    rolesOptions: {
      default: function() {
        return [];
      }
    }
  },
  data() {
    return {
      checkPhoneNumber,
      checkNotChineseandnoelse,
      checkDigest40,
      accountForm: this.$form.createForm(this),
      isPosting: false,
      postData: {
        is_active: true,
        role_ids: []
      },
    };
  },
  mounted() {
    if (this.data && this.data.id) {
      this.postData = JSON.parse(JSON.stringify(this.data));
      const that = this;
      setTimeout(function() {
        that.refillData();
      }, 0);
    }
  },
  methods: {
    // 回填数据
    refillData() {
      this.$nextTick(() => {
        this.accountForm.setFieldsValue({
          "postData.username": this.postData.username,
          "postData.mobile": this.postData.mobile,
          "postData.employee_no": this.postData.employee_no,
          "postData.department": this.postData.department,
          "postData.station": this.postData.station,
          "postData.email": this.postData.email,
          "postData.is_active": this.postData.is_active,
          "postData.role_ids": this.postData.role_ids
        });
      });
    },
    // 新增
    handleCreateEditAccountData() {
      this.accountForm.validateFields(async (formErr, values) => {
        if (!formErr) {
          this.isPosting = true;
          if (this.data && this.data.id) {
            // 修改
            values.postData.id = this.data.id
            const { err, res } = await patchAdminUser(values.postData);
            if (!err) {
              if (res.success) {
                this.$message.success(res.message);
                this.handleHideThisModal(0);
                await this.$store.dispatch("getRights");
              }
            }
          } else {
            // 新增
            const { err, res } = await postAdminUser(values.postData);
            if (!err) {
              if (res.success) {
                this.$message.success(res.message);
                this.handleHideThisModal(1);
                await this.$store.dispatch("getRights");
              }
            }
          }
          this.isPosting = false;
        }
      });
    },
    // 隐藏modal
    handleHideThisModal(page = -1) {
      this.$emit("callback", page);
    }
  }
};
</script>
<style lang="less" scoped>
.mr-20 {
  margin-right: 20px;
}
</style>
