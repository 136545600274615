<!--
 * @Author: 前胡
 * @LastEditors: 胡椒
 * @Date: 2020-03-03 17:30:54
 * @LastEditTime: 2020-08-26 15:18:27
 * @Description: 用户管理
 -->
<template>
  <div id="userManager">
    <AccountSearchBar
      @callback="accountSearchCallback"
      :searchParams="searchParams"
      :loading="listLoading"
      v-auth="'account_manage_list'"
    />
    <div class="option-btns">
      <a-button
        v-auth="'account_manage_add_ac'"
        type="primary"
        class="option-btn"
        html-type="submit"
        @click="handleShowAccountModal({})"
      >添加账号</a-button>
    </div>
    <a-table
      :scroll="{ x: 1200, y: tableScrollY }"
      v-auth="'account_manage_list'"
      :rowKey="record => record.id"
      :columns="accountColumns"
      :dataSource="list"
      :pagination="pagination"
      :loading="listLoading"
      :locale="{ emptyText: '暂无用户' }"
      @change="handleTableChange"
    >
      <template slot="status" slot-scope="record">
        <a-tag v-if="record.is_active===false" color="red">停用</a-tag>
        <a-tag v-if="record.is_active===true" color="green">正常</a-tag>
      </template>
      <template slot="rolesSlots" slot-scope="record">
        <p style="white-space:nowrap; text-overflow:ellipsis; overflow:hidden;">{{record.roles.map(i=>i.role_name).join(',')}}</p>
      </template>
      <template slot="operation" slot-scope="record">
        <a-button
          type="primary"
          size="small"
          class="mr-10"
          style="margin-bottom: 5px"
          @click="handleShowAccountModal(record)"
          v-auth="'account_manage_modify_ac'"
        >编辑</a-button>
        <a-button
          size="small"
          class="mr-10"
          style="margin-bottom: 5px"
          @click="handleShowAccountDetailModal(record)"
        >查看</a-button>
        <a-button
          size="small"
          class="mr-10"
          @click="handleConfirmResetPassword(record)"
          v-auth="'account_manage_reset_password'"
        >重置密码</a-button>
        <a-button
          size="small"
          class="mr-10"
          @click="handleConfirmDelUser(record)"
          v-auth="'account_manage_del_ac'"
        >删除</a-button>
      </template>
    </a-table>
    <!--添加修改账号-->
    <a-drawer
      :title="editMode?'编辑账号':'添加账号'"
      width="850px"
      placement="right"
      :destroyOnClose="true"
      @close="hideAccountModal"
      :visible="accountModalVisiable"
    >
      <AccountDrawer
        :data="tempAccountData"
        :rolesOptions="allRoles"
        @callback="hideAccountModal"
      ></AccountDrawer>
    </a-drawer>

    <!--查看账号-->
    <a-drawer
      title="查看"
      width="850px"
      placement="right"
      @close="hideAccountDetailModal"
      :visible="accountDetailModalVisiable"
    >
      <AccountDetailDrawer
        :data="tempAccountData"
        :rolesOptions="allRoles"
      />
    </a-drawer>
  </div>
</template>

<script>
import {
  getAccountList,
  delAdminUser,
  resetPassword,
  getRoleList
} from "@/service/setting";
import AccountSearchBar from "@/components/admin/setting/account/account-search-bar";
import AccountDrawer from "@/components/admin/setting/account/drawer-account";
import AccountDetailDrawer from "@/components/admin/setting/account/drawer-account-detail";
import { checkPer } from "@/utils/index";

// 寄样申请列表
const accountColumns = [
  {
    title: "手机号",
    dataIndex: "mobile",
    fixed: 'left',
    align: "center",
    width: 150
  },
  {
    title: "工号",
    fixed: 'left',
    dataIndex: "employee_no",
    align: "left",
    width: 140
  },
  {
    title: "花名",
    fixed: 'left',
    dataIndex: "username",
    align: "left",
    width: 120
  },
  {
    title: "部门",
    fixed: 'left',
    dataIndex: "department",
    align: "left",
    width: 150
  },
  {
    title: "岗位",
    dataIndex: "station",
    align: "left",
    width: 150
  },
  {
    title: "角色",
    dataIndex: "",
    align: "left",
    width: 300,
    scopedSlots: { customRender: "rolesSlots" }
  },
  {
    title: "状态",
    align: "center",
    width: 100,
    scopedSlots: { customRender: "status" }
  },
  {
    title: "邮箱",
    dataIndex: "email",
    align: "left",
    width: 220
  },
  {
    title: "创建时间",
    dataIndex: "create_time",
    align: "center",
    width: 220
  },
  {
    title: "操作",
    align: "center",
    fixed: 'right',
    width: 300,
    scopedSlots: { customRender: "operation" }
  }
];

export default {
  name: "AccountSetting",
  components: {
    AccountSearchBar,
    AccountDrawer,
    AccountDetailDrawer
  },
  data() {
    return {
      tableScrollY: 600,
      form: this.$form.createForm(this),
      editMode: false,
      accountColumns,
      // 添加编辑账号弹层状态
      accountModalVisiable: false,
      accountDetailModalVisiable: false,
      // 临时账号数据
      tempAccountData: {},
      listLoading: true,
      list: [],
      allRoles: [],
      searchParams: {
        search: "",
        is_active: undefined
      },
      pagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ["10", "20", "30", "40", "50"],
        showTotal: total =>
          `共 ${total} 条记录 第 ${this.pagination.current}/${Math.ceil(
            total / this.pagination.pageSize
          )} 页`,
        showQuickJumper: true,
        showSizeChanger: true,
        onShowSizeChange: (current, pageSize) =>
          (this.pagination.pageSize = pageSize)
      }
    };
  },
  created() {
    const windowHeight = document.documentElement.clientHeight || 1000;
    this.tableScrollY = windowHeight - 325;
  },
  mounted() {
    this.getAccountList();
    this.getAllRoles();
  },
  methods: {
    // 获取账号列表
    async getAccountList() {
      if (!checkPer("account_manage_list")) {
        this.$message.error("无权限获取列表");
        return false;
      }
      const data = Object.assign(
        { page: this.pagination.current, page_size: this.pagination.pageSize },
        this.searchParams
      );
      this.listLoading = true;
      const { err, res } = await getAccountList(data);
      this.listLoading = false;
      if (!err) {
        if (res.success) {
          const pagination = { ...this.pagination };
          this.list = res.data.results;
          pagination.total = res.data.count;
          this.pagination = pagination;
        } else {
          this.$message.warning(res.message);
        }
      }
    },
    // 表格分页
    handleTableChange(pagination) {
      if (pagination) this.pagination.current = pagination.current;
      this.getAccountList();
    },
    // 显示添加修改账号
    handleShowAccountModal(data) {
      if (data.username) {
        this.editMode = true;
        this.tempAccountData = JSON.parse(JSON.stringify(data));
        this.tempAccountData.role_ids = this.tempAccountData.roles.map(
          i => i.role_id
        );
      } else {
        this.editMode = false;
        this.tempAccountData = {};
        this.tempAccountData.role_ids = [];
      }
      this.accountModalVisiable = true;
    },
    // 显示查看
    handleShowAccountDetailModal(data) {
      this.tempAccountData = JSON.parse(JSON.stringify(data));
      this.tempAccountData.role_ids = this.tempAccountData.roles.map(
        i => i.role_id
      );
      this.accountDetailModalVisiable = true;
    },
    // 搜索查询按钮
    accountSearchCallback(res) {
      this.pagination.current = 1;
      this.searchParams = Object.assign({}, res);
      this.getAccountList();
    },
    // 隐藏用户信息drawer
    hideAccountModal(isNeedFresh = -1) {
      this.accountModalVisiable = false;
      if (isNeedFresh === 1) {
        this.pagination.current = 1;
        this.getAccountList();
      }
      if (isNeedFresh === 0) {
        this.getAccountList();
      }
    },
    // 隐藏用户详细信息drawer
    hideAccountDetailModal() {
      this.accountDetailModalVisiable = false;
    },
    // 重置密码
    handleConfirmResetPassword(record) {
      const that = this;
      this.$confirm({
        title: "重置密码",
        content: () => `确认将用户 ${record.username} 密码重置为Gm099527`,
        async onOk() {
          const { err, res } = await resetPassword({
            id: record.id,
            is_reset_password: true
          });
          if (!err && res.success) {
            that.$message.success(res.message);
          }
        }
      });
    },
    // 删除用户
    handleConfirmDelUser(record) {
      const that = this;
      this.$confirm({
        title: "删除账号",
        content: () => `确认要删除账号 “${record.username} ?`,
        async onOk() {
          const { err, res } = await delAdminUser({ id: record.id });
          if (!err && res.success) {
            that.$message.success("账号已删除");
            that.$destroyAll();
            that.getAccountList();
          }
        }
      });
    },
    // 获取角色列表
    async getAllRoles() {
      const data = {
        is_get_rights: false,
        page: 1,
        page_size: 10000,
        search: ""
      };
      this.listLoading = true;
      const { err, res } = await getRoleList(data);
      this.listLoading = false;
      if (!err) {
        if (res.success) {
          const roles = JSON.parse(JSON.stringify(res.data));
          const rss = roles.results;
          const ar = rss.map(i => ({ label: i.role_name, value: i.role_id }));
          this.allRoles = ar;
        }
      }
    }
  }
};
</script>
<style lang='less' scoped>
  #userManager{
    /deep/ .ant-table-body {
      //样式穿透
      overflow-x: auto !important;
    }
    .ant-table-body {
      //样式穿透
      overflow-x: auto !important;
    }
  }
.option-btns {
  margin-bottom: 20px;
  margin-top: 10px;
  .option-btn {
    margin-right: 10px;
  }
}
.mr-10 {
  margin-right: 10px;
}
</style>
